import {TOBACCO_DEPARTMENT} from '@hy-vee/shared-utils-aisles-online/lib/product-eligibility/product-eligibility-types';

import {cart as Cart, OrderV3, FulfillmentType, ProductCardQuery, product as Product, CouponV4} from 'codegen/graphql';
import {graphqlClient} from 'client/graphql/graphql-client';
import {
    deleteCartItemAction,
    updateCartItemAction
} from 'client/graphql/mutations/mutation-actions/product-card-actions';
import {createCartItemMutation} from 'client/graphql/mutations/product-card-mutations';
import {addToCartDataLayer, removeFromCartDataLayer} from 'client/services/analytics-service';
import {createClippedCouponIfNotAlreadyClippedV4} from 'client/services/coupon-service';
import {getWarningMessageForProduct, isEligibleForLocker} from 'client/utils/product-helpers';
import {EACH, POUND} from 'server/enums/aisles-online-api-quantity-type';
import {BEST_MATCH} from 'server/enums/aisles-online-api-substitution-preference';

import {
    submitProdxAddToCart,
    submitProdxDecrementCart,
    submitProdxIncrementCart,
    submitProdxRemoveToCart
} from '../../../graphql/mutations/mutation-actions/prodx-shelf-mutations-actions';
import {updateCartCacheAfterCartItemCreate} from '../../../graphql/mutations/mutation-helpers/cart-mutation-helpers';

interface IOnChangeAmountInCartParams {
    aisleId?: string;
    cartItem: any;
    coupon: any;
    createAdClickEvent: () => void;
    createCartItemCallback: Function;
    customer: any;
    product: any;
    responseId?: string | null;
    responseProductId?: string | null;
    storeProduct: any;
    swimlaneType: any;
    position?: number;
}

export const onChangeAmountInCart =
    ({
        aisleId,
        cartItem,
        coupon,
        createAdClickEvent,
        createCartItemCallback,
        customer,
        product,
        responseId,
        responseProductId,
        storeProduct,
        swimlaneType,
        position
    }: IOnChangeAmountInCartParams) =>
    (quantity: any) => {
        if (!cartItem) {
            if (coupon && customer.fuelSaverCardUuid) {
                createClippedCouponIfNotAlreadyClippedV4(customer.fuelSaverCardUuid, coupon.couponId);
            }

            addToCartDataLayer({
                coupon,
                position,
                product,
                quantity,
                swimlaneType
            });

            createAdClickEvent();

            submitProdxAddToCart({
                aisleId,
                responseId,
                responseProductId
            });

            return createCartItemCallback({
                client: graphqlClient(),
                mutation: createCartItemMutation,
                update: (cache, data) =>
                    updateCartCacheAfterCartItemCreate(data.data.createCartItem, customer.customerId),
                variables: {
                    createCartItemInput: {
                        productId: Number(product.productId),
                        quantity,
                        quantityType: storeProduct.isWeighted && !product.averageWeight ? POUND : EACH,
                        storeProductId: Number(storeProduct.storeProductId),
                        substitutionPreference: BEST_MATCH
                    },
                    customerId: customer.customerId
                }
            });
        }

        const quantityDelta = Math.abs(quantity - cartItem.quantity);

        if (cartItem.quantity < quantity) {
            addToCartDataLayer({
                coupon,
                position,
                product,
                quantity: quantityDelta,
                swimlaneType
            });
            submitProdxIncrementCart({
                aisleId,
                responseId,
                responseProductId
            });
        } else {
            removeFromCartDataLayer({
                coupon,
                position,
                product,
                quantity: quantityDelta,
                swimlaneType
            });
            submitProdxDecrementCart({
                aisleId,
                responseId,
                responseProductId
            });
        }

        if (quantity === 0) {
            submitProdxRemoveToCart({
                aisleId,
                responseId,
                responseProductId
            });

            return deleteCartItemAction(cartItem);
        }

        return updateCartItemAction({
            cartItemId: Number(cartItem.cartItemId),
            quantity
        });
    };

export const getWarningMessageForProductCardFromCart = (
    activeCart: Cart | null,
    productData: ProductCardQuery
): string | null => {
    const product = productData.product;
    const storeProduct = product?.storeProduct;
    const pickupLocation = activeCart?.pickupLocation;
    const fulfillmentType = activeCart?.fulfillmentType;
    const deliveryIneligible = product?.isNotEligibleForDelivery;
    const fulfillmentLocation = activeCart?.fulfillmentLocation;
    const isStoreProductLockerEligible = isEligibleForLocker(pickupLocation, product);
    const isPickupAndSelfFulfilled = activeCart?.fulfillmentType === FulfillmentType.PICKUP && !pickupLocation;
    const isProductAvailableInStoreWithPrice = Boolean(
        storeProduct
            ? product?.item?.retailItems?.length && Boolean(product?.item?.retailItems[0]?.ecommerceTagPrice)
            : storeProduct
    );

    if (!isProductAvailableInStoreWithPrice && Boolean(storeProduct)) {
        console.error('Item API missing store product', {
            productId: product?.productId,
            storeId: activeCart?.storeId,
            storeProductId: product?.storeProduct?.storeProductId
        });
    }

    return getWarningMessageForProduct({
        deliveryIneligible,
        fulfillmentCenterCanFulfillAlcohol: fulfillmentLocation?.canFulfillAlcohol,
        fulfillmentType,
        isPickupAndSelfFulfilled,
        isStoreProductAlcoholic: Boolean(storeProduct?.isAlcohol),
        isStoreProductAvailable: isProductAvailableInStoreWithPrice,
        isStoreProductLockerEligible,
        isStoreProductTobacco: Number(storeProduct?.departmentId) === TOBACCO_DEPARTMENT,
        pickupLocationHasLocker: pickupLocation?.pickupLocationHasLocker
    });
};

export const getWarningMessageForProductCardFromOrder = (
    orderV3: OrderV3,
    productData: ProductCardQuery
): string | null => {
    const product = productData.product;
    const storeProduct = product?.storeProduct;
    const fulfillmentType = orderV3.fulfillmentLocation?.fulfillmentType;
    const deliveryIneligible = product?.isNotEligibleForDelivery;
    const pickupLocation = orderV3.pickupLocation;
    const fulfillmentLocation = orderV3.fulfillmentLocation;
    const isPickupAndSelfFulfilled =
        orderV3.fulfillmentLocation?.fulfillmentType === FulfillmentType.PICKUP && !pickupLocation;
    const isStoreProductLockerEligible = isEligibleForLocker(pickupLocation, product);

    return getWarningMessageForProduct({
        deliveryIneligible,
        fulfillmentCenterCanFulfillAlcohol: fulfillmentLocation?.canFulfillAlcohol,
        fulfillmentType,
        isPickupAndSelfFulfilled,
        isStoreProductAlcoholic: Boolean(storeProduct?.isAlcohol),
        isStoreProductAvailable: Boolean(storeProduct),
        isStoreProductLockerEligible,
        isStoreProductTobacco: Number(storeProduct?.departmentId) === TOBACCO_DEPARTMENT,
        pickupLocationHasLocker: pickupLocation?.pickupLocationHasLocker
    });
};

export const getCouponForProduct = (data: ProductCardQuery): CouponV4 | null =>
    data.product?.couponProductV4?.couponsV4.length ? (data.product.couponProductV4.couponsV4[0] as CouponV4) : null;

interface IProductCardProductData {
    averageWeight: number | null;
    name: string;
    productImages: any;
    size: string;
}

export const getItemAvgWeight = (product: Product): IProductCardProductData => {
    if (product.item) {
        return {
            averageWeight: product.item.unitAverageWeight,
            name: product.name,
            productImages: product.productImages,
            size: product.size
        };
    }

    return product;
};

interface IPriceable {
    basePrice: number;
    isWeighted: boolean;
    price: number;
    priceMultiple: number;
    perksPrice: number | null;
    perksPriceMultiple: number | null;
}

export const getItemPricing = (product: Product): IPriceable | null => {
    if (product.item?.retailItems?.[0]) {
        const retailItem = product.item.retailItems[0];

        return {
            basePrice: retailItem.basePrice,
            isWeighted: Boolean(retailItem.soldByUnitOfMeasure && retailItem.soldByUnitOfMeasure.code !== 'CT'),
            perksPrice: retailItem.memberTagPrice,
            perksPriceMultiple: retailItem.memberTagPriceQuantity,
            price: retailItem.ecommerceTagPrice ?? retailItem.tagPrice,
            priceMultiple: retailItem.ecommerceTagPriceQuantity ?? retailItem.tagPriceQuantity
        };
    }

    if (!product.storeProduct) {
        return null;
    }

    return {
        ...product.storeProduct,
        perksPrice: null,
        perksPriceMultiple: null
    };
};
