import Modal from '@hy-vee/web-core/lib/components/modal';

import ClientOnlyPortal from '../client-only-portal';

import CouponModalBody from './coupon-modal-body';

const ProductCouponModal = ({couponId, setCouponModalId}) => {
    const close = () => {
        setCouponModalId(null);
    };

    return couponId ? (
        <ClientOnlyPortal selector="body">
            <Modal data-testid={'coupon-modal'} onClose={close} padding={false}>
                <CouponModalBody couponId={couponId} />
            </Modal>
        </ClientOnlyPortal>
    ) : null;
};

export default ProductCouponModal;
