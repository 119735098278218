import {useQuery} from '@apollo/client';
import {IDType} from '@hy-vee/ts-utils';
import ProductCardSkeleton from '@hy-vee/web-ecommerce/lib/components/product-card-skeleton';
import ProductCard from '@hy-vee/web-ecommerce/lib/components/product-card/index';
import {FC, PropsWithChildren, memo, useState} from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';

import {
    ProductCardQuery_product,
    ProductCardQuery_product_couponProductV4_couponsV4,
    ProductCardQuery_product_storeProduct
} from 'autogen/ProductCardQuery';
import {toggleActiveModal} from 'client/action-creators/toggle-active-modal-action-creator';
import {useFeatureToggle} from 'client/context/feature-toggle';
import {
    EWIC_STORES,
    RESERVATION_DRAWER,
    FULL_BLEED_IMAGES,
    WEB_SNAP_BADGE_DISPLAY
} from 'client/enums/feature-toggle-names';
import {
    submitProdxProductView,
    submitProdxRightClick
} from 'client/graphql/mutations/mutation-actions/prodx-shelf-mutations-actions';
import {productCardQuery} from 'client/graphql/queries/product-queries';
import {useCustomer, useCustomerData, useIsAuthenticated} from 'client/hooks/customer-hooks';
import {useReservationDrawer} from 'client/hooks/use-reservation-drawer';
import {reportEvolyticsImpression, reportProductClick} from 'client/services/analytics-service';
import {redirectToLogin} from 'client/utils/redirect-to-login';
import {createRibbons} from 'client/utils/ribbon-helpers';
import {getAislesOnlineDetailsPageUrl} from 'client/utils/view-helpers/view-helpers';
import {getElementPosition, useInViewEvent} from 'client/hooks/use-in-view-event';
import GTMEvent from 'client/enums/gtm-event';
import {product as Product, ProductCardQuery, ProductCardQueryVariables} from 'codegen/graphql';

import {THREE_LEGGED_AUTH_TYPE, TWO_LEGGED_AUTH_TYPE} from '../../../enums/auth-types';
import {useLocationId} from '../../../hooks/location-hooks';
import {useAislesOnlineRouter} from '../../../hooks/use-aisles-online-router';
import ProductCouponModal from '../coupons/product-coupon-modal';
import AddToListsModal from '../lists/add-to-lists/add-to-lists-modal';

import {getCouponForProduct, getItemAvgWeight, getItemPricing} from './product-card-helpers';
import {isSnapEligible} from 'client/utils/product-helpers';
import SwimlaneType from 'client/enums/swimlane-types';

interface ProductWrapperProps {
    isList?: boolean;
}

const ProductWrapper = styled.div<ProductWrapperProps>`
    display: flex;
    justify-content: center;
    border-radius: 16px;
    width: 100%;
    height: 100%;

    @media screen and (min-width: 768px) {
        width: 198px;
    }
`;

interface IProductCardQueryVariables extends ProductCardQueryVariables {
    authType: string;
}

export interface IProductCardSimpleProps {
    adId?: string | null;
    isSponsored?: boolean | null;
    aisleId?: string;
    buttonText?: string;
    buttonAriaLabel?: string;
    href?: string;
    index?: number;
    isFrequentPurchase?: boolean;
    isPersonalized?: boolean;
    onClick?: () => void;
    productId: IDType;
    responseProductId?: string | null;
    productData?: Product | null;
    setProduct?: (product: ProductCardQuery_product) => void;
    showListIcon?: boolean;
    showUnavailableProducts?: boolean;
    width?: string;
    onChangeAmountInCart: (
        product: ProductCardQuery_product,
        storeProduct: ProductCardQuery_product_storeProduct | null,
        customer: any,
        coupon: ProductCardQuery_product_couponProductV4_couponsV4 | null
    ) => (quantity: number) => void;
    isAddToCartLoading: boolean;
    quantity: number;
    hasStoreSelected: boolean;
    getWarning: (ProductCardQuery) => string | null;
    variant?: string;
    /**
     * @deprecated This property no longer has any function and can safely be removed.
     */
    imgSize?: string;
    locationIds?: string[];
    pickupLocationHasLocker?: boolean;
    slotTypeId?: number | null;
    sponsored?: string | null;
    responseId?: string | null;
    storeId?: number;
    isList?: boolean;
    swimlaneType?: any;
}

const ProductCardSimple: FC<PropsWithChildren<PropsWithChildren<IProductCardSimpleProps>>> = ({
    adId,
    isSponsored,
    aisleId,
    buttonText,
    buttonAriaLabel,
    productId,
    href: givenHref,
    index,
    isAddToCartLoading,
    isFrequentPurchase,
    isPersonalized,
    onClick,
    productData = null,
    quantity,
    responseProductId = null,
    setProduct,
    variant,
    width,
    onChangeAmountInCart,
    showListIcon = true,
    showUnavailableProducts = true,
    slotTypeId,
    sponsored,
    hasStoreSelected,
    getWarning,
    locationIds: locationIdOverride,
    pickupLocationHasLocker: pickupLocationHasLockerOverride,
    responseId,
    storeId: storeIdOverride,
    isList = false,
    swimlaneType
}) => {
    const [modalCouponId, setCouponModalId] = useState<IDType | null>(null);
    const [modalProductId, setModalProductId] = useState<IDType | null>(null);
    const {setIsOpen} = useReservationDrawer();
    const dispatch = useDispatch();
    const {featureEnabled, featuresEnabled} = useFeatureToggle();

    const wicEnabled = featuresEnabled([EWIC_STORES]);
    const isReservationDrawerEnabled = featureEnabled(RESERVATION_DRAWER);
    const isFullBleedEnabled = featureEnabled(FULL_BLEED_IMAGES);
    const snapEnabled = featuresEnabled([WEB_SNAP_BADGE_DISPLAY]);

    const launchReservationStepper = () => {
        if (isReservationDrawerEnabled) {
            setIsOpen(true);
        } else {
            dispatch(toggleActiveModal('RESERVATION_STEPPER'));
        }
    };

    const router = useAislesOnlineRouter();
    const customer = useCustomer();
    const isAuthenticated = useIsAuthenticated();
    const {storeId: cartStoreId, pickupLocationHasLocker: cartPickupLocationHasLocker, customerId} = useCustomerData();

    const authType = customerId ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE;

    const storeId = storeIdOverride ?? cartStoreId;
    const {locationIds} = useLocationId(storeId?.toString());
    const locationId = locationIdOverride ?? locationIds;
    const pickupLocationHasLocker = pickupLocationHasLockerOverride ?? cartPickupLocationHasLocker;

    const {data, loading: queryLoading} = useQuery<ProductCardQuery, IProductCardQueryVariables>(productCardQuery, {
        errorPolicy: 'all',
        skip: Boolean(productData),
        variables: {
            authType,
            locationIds: locationId,
            pickupLocationHasLocker,
            productId: Number(productId),
            retailItemEnabled: Boolean(locationId),
            storeId,
            targeted: Boolean(customer.fuelSaverCardUuid),
            wicEnabled
        }
    });

    const ad = {
        destination: givenHref ?? getAislesOnlineDetailsPageUrl(data?.product?.productId, data?.product?.name),
        imageTitle: data?.product?.name,
        name: data?.product?.name,
        title: GTMEvent.ProductCardSimple
    };

    const {ref} = useInViewEvent({triggerOnce: true}, (elementPosition) =>
        reportEvolyticsImpression(
            {
                ...ad,
                ...elementPosition
            },
            customer,
            GTMEvent.EvolyticsImpression
        )
    );

    if (queryLoading) {
        return <ProductCardSkeleton variant={variant} />;
    }

    const product = (productData ? productData : data?.product) as Product;

    if (!product) {
        return null;
    }

    const isWicEligible = !!product.item?.WicItems?.length;
    const storeProduct = product.storeProduct;
    const coupon = getCouponForProduct({
        __typename: 'Query',
        product
    } as ProductCardQuery);
    const warning = getWarning({product});
    const href = givenHref ?? getAislesOnlineDetailsPageUrl(product.productId, product.name);
    const storeProductDescription = storeProduct?.storeProductDescriptions?.[0]?.description;
    const cardIsHorizontal = variant === 'horizontal';
    const retailItem = product.item?.retailItems?.[0];

    const listOfRibbons = storeProduct
        ? createRibbons({
              coupon,
              isList,
              isSnapEligible: isSnapEligible(retailItem, snapEnabled),
              isWicEligible,
              onSeeCouponDetailsClick: setCouponModalId,
              sources: isFrequentPurchase ? [{key: 'frequentPurchases'}] : undefined,
              ...storeProduct
          })
        : null;

    if (!showUnavailableProducts && (!storeProduct || !retailItem)) {
        return null;
    }

    if (setProduct) {
        setProduct(product as Product);
    }

    const productWrapperProps = {
        'aria-posinset': index,
        'aria-setsize': -1,
        'data-citrus-ad-id': adId,
        'data-personalized-product': Boolean(isPersonalized),
        'data-placement-type': slotTypeId,
        'data-sponsored-product-provider': sponsored,
        'data-testid': 'product-card'
    };

    if (!adId) {
        delete productWrapperProps['data-citrus-ad-id'];
    }

    const onProductClick = (event) => {
        reportProductClick(product, swimlaneType, index);
        reportEvolyticsImpression(
            {
                ...ad,
                ...getElementPosition(event.currentTarget)
            },
            customer,
            GTMEvent.EvolyticsClick
        );

        if (isAuthenticated) {
            submitProdxProductView({
                aisleId,
                responseId,
                responseProductId
            });
        }

        if (onClick) {
            onClick();
        }
    };

    const onRightClick = () => {
        if (isAuthenticated) {
            submitProdxRightClick({
                aisleId,
                responseId,
                responseProductId
            });
        }
    };

    return (
        <ProductWrapper isList={isList} onContextMenu={onRightClick} {...productWrapperProps} ref={ref}>
            <ProductCard
                amountInCart={quantity}
                aria-label="Product"
                buttonAriaLabelOverride={buttonAriaLabel}
                buttonTextOverride={buttonText}
                foodHealthScore={swimlaneType === SwimlaneType.BETTER_FOR_YOU && product?.item?.nutrition?.score}
                hasStoreSelected={hasStoreSelected}
                href={href}
                isAddToCartLoading={isAddToCartLoading}
                isAuthenticated={isAuthenticated}
                isFullBleedImage={isFullBleedEnabled}
                isList={isList}
                isSponsored={Boolean(adId) || isSponsored}
                notAvailableWarningMessage={storeId && isAuthenticated && warning ? warning : ''}
                onAddToList={showListIcon ? () => setModalProductId(product.productId) : false}
                onChangeAmountInCart={onChangeAmountInCart(product, storeProduct, customer, coupon)}
                onClick={onProductClick}
                onLoginUser={() => redirectToLogin(router.asPathWithBasePath)}
                onSelectStore={launchReservationStepper}
                product={getItemAvgWeight(product)}
                promotionText={storeProductDescription}
                ribbons={listOfRibbons}
                storeProduct={getItemPricing(product)}
                variant={variant}
                width={cardIsHorizontal ? undefined : width}
            />
            <ProductCouponModal couponId={modalCouponId} setCouponModalId={setCouponModalId} />
            <AddToListsModal
                aisleId={aisleId}
                data-testid="add-to-lists-modal"
                onClose={() => setModalProductId(null)}
                productId={modalProductId}
                responseId={responseId}
                responseProductId={responseProductId}
            />
        </ProductWrapper>
    );
};

export default memo(ProductCardSimple);
