import {FC, PropsWithChildren, memo} from 'react';
import {useMutation} from '@apollo/client';
import {IDType} from '@hy-vee/ts-utils';

import {
    ProductCardQuery,
    createCartItemProductMutation,
    cartItem as CartItem,
    RetailItem,
    storeProduct as StoreProduct
} from 'codegen/graphql';
import {createCartItemMutation} from 'client/graphql/mutations/product-card-mutations';
import {useCitrusActions} from 'client/hooks/citrus-hooks';
import {useCustomerData} from 'client/hooks/customer-hooks';
import {useCartItems} from 'client/hooks/cart-hooks';

import {default as ProductCardSimple, IProductCardSimpleProps} from './product-card-simple';
import {getWarningMessageForProductCardFromCart, onChangeAmountInCart} from './product-card-helpers';

const INCLUDE_CART_ITEMS = true;

export interface IOnNewItemAddedProps {
    storeProduct: StoreProduct;
    retailItem: RetailItem;
}
export interface IProductCardCartProps
    extends Omit<
        IProductCardSimpleProps,
        'getWarning' | 'hasStoreSelected' | 'isAddToCartLoading' | 'onChangeAmountInCart' | 'quantity'
    > {
    productId: IDType;
    aisleId?: string;
    adId?: string | null;
    slotTypeId?: number | null;
    sponsored?: string | null;
    swimlaneType?: string;
    responseProductId?: string | null;
    onNewItemAdded?: (cartItem: CartItem) => void;
    isList?: boolean;
}

const ProductCardCart: FC<PropsWithChildren<PropsWithChildren<IProductCardCartProps>>> = ({
    onNewItemAdded,
    productId,
    responseProductId,
    adId,
    slotTypeId,
    swimlaneType = '',
    aisleId,
    responseId,
    isList = false,
    ...props
}) => {
    const {activeCart} = useCustomerData(INCLUDE_CART_ITEMS);
    const {getCartItem} = useCartItems(true);
    const {reportClick} = useCitrusActions();

    const [createCartItemCallback, {loading}] = useMutation(createCartItemMutation, {
        onCompleted: (data: createCartItemProductMutation) => {
            const {createCartItem} = data;

            if (!createCartItem) return;

            if (typeof onNewItemAdded === 'function') {
                onNewItemAdded(createCartItem as CartItem);
            }
        }
    });

    const cartItem = getCartItem(productId);

    const getWarning = (data: ProductCardQuery) => getWarningMessageForProductCardFromCart(activeCart, data);

    const onChangeAmountInCartWrapper = (product, storeProduct, customer, coupon) => {
        return onChangeAmountInCart({
            aisleId,
            cartItem,
            coupon,
            createAdClickEvent: () => adId && reportClick(adId),
            createCartItemCallback,
            customer,
            position: props.index,
            product,
            responseId,
            responseProductId,
            storeProduct,
            swimlaneType
        });
    };

    const quantity = cartItem ? cartItem.quantity : 0;

    return (
        <ProductCardSimple
            {...props}
            adId={adId}
            aisleId={aisleId}
            getWarning={getWarning}
            hasStoreSelected={Boolean(activeCart?.storeId)}
            isAddToCartLoading={loading}
            isList={isList}
            onChangeAmountInCart={onChangeAmountInCartWrapper}
            productId={productId}
            quantity={quantity}
            responseId={responseId}
            responseProductId={responseProductId}
            slotTypeId={slotTypeId}
            swimlaneType={swimlaneType}
        />
    );
};

export default memo(ProductCardCart);
