import {LinkButton} from '@hy-vee/web-core';
import {IDType} from '@hy-vee/ts-utils';
import styled from 'styled-components';
import {
    COUPON,
    FREQUENT_PURCHASE,
    FUEL_SAVER,
    ON_SALE,
    WIC_ELIGIBLE,
    SNAP_ELIGIBLE
} from '@hy-vee/web-ecommerce/lib/components/ribbon/ribbon-enums';
import {ClpeRewardTypeDescription} from '@hy-vee/shared-utils-aisles-online/lib/enums/clpe-reward-types';

import ClpeRibbonWithTooltip from '../views/components/cart/cart-item/clpe-ribbon-with-tooltip';
import {NEW_PRICE} from '../enums/reward-types';
import {REDEEMED, SHUT_OFF} from '../enums/coupon-offer-states';

import {displayCentsUnderADollar, displayPrice} from './view-helpers/calculation-view-helpers';
import {postMessageToIframeParent} from './iframe-helpers';

const StyledLinkButton = styled(LinkButton)`
    color: var(--color-green--30);
    font-size: 10px;
    height: 0;
    line-height: 0;
    text-align: left;

    :hover,
    :focus {
        box-shadow: none;
        color: var(--color-green--30);
        text-decoration: underline;
    }
`;

export interface IRibbon {
    ribbonType: string;
    description?: JSX.Element | string;
    isList?: boolean;
}

const createFrequentPurchaseRibbon = (frequentPurchaseSource): IRibbon | null =>
    frequentPurchaseSource
        ? {
              ribbonType: FREQUENT_PURCHASE
          }
        : null;

const createOnSaleRibbon = (onSale, amountOffPerItemPromotion, clpePromotions, quantity): IRibbon | null => {
    if (clpePromotions?.length) {
        const shouldDisplayOnSaleRibbonForClpe = clpePromotions.some((itemPromotion) =>
            [
                ClpeRewardTypeDescription.CASH,
                ClpeRewardTypeDescription.NEW_PRICE,
                ClpeRewardTypeDescription.PERCENTAGE_OFF,
                ClpeRewardTypeDescription.AMOUNT_PER_POUND,
                ClpeRewardTypeDescription.NEW_PRICE_PER_POUND,
                ClpeRewardTypeDescription.FREE_ITEM,
                ClpeRewardTypeDescription.AMOUNT_PER_ITEM,
                ClpeRewardTypeDescription.NEW_PRICE_PER_ITEM,
                ClpeRewardTypeDescription.NEW_PRICE_PER_QUANTITY
            ].includes(itemPromotion.rewardType)
        );

        if (shouldDisplayOnSaleRibbonForClpe) {
            return {
                ribbonType: ON_SALE
            };
        }
    }

    if (amountOffPerItemPromotion) {
        const requiredQuantity = amountOffPerItemPromotion.threshold.find((threshold) => threshold.type === 'Quantity')
            ?.value;

        return requiredQuantity && quantity >= requiredQuantity
            ? {
                  description: (
                      <ClpeRibbonWithTooltip
                          text={`Save ${displayPrice(amountOffPerItemPromotion.rewardValue / 100)}`}
                      />
                  ),
                  ribbonType: ON_SALE
              }
            : null;
    }

    return (
        onSale && {
            ribbonType: ON_SALE
        }
    );
};

const createFuelSaverRibbon = (onFuelSaver: boolean, fuelSaver: IDType, clpePromotions): IRibbon | null => {
    let isClpeFuelSaver = false;
    let clpeFuelSaverAmountOff = 0;

    if (clpePromotions?.length) {
        isClpeFuelSaver = clpePromotions.some(
            (promotion) => promotion.rewardType === ClpeRewardTypeDescription.MEMBER_ACCOUNT
        );

        clpeFuelSaverAmountOff =
            clpePromotions.reduce(
                (total, current) =>
                    total +
                    (current.rewardType === ClpeRewardTypeDescription.MEMBER_ACCOUNT ? current.rewardAmount : 0),
                0
            ) / 100;
    }

    const fuelSaverAmountOff = isClpeFuelSaver ? clpeFuelSaverAmountOff : fuelSaver;

    return onFuelSaver || isClpeFuelSaver
        ? {
              description:
                  typeof fuelSaver === 'string'
                      ? fuelSaver
                      : `${displayCentsUnderADollar(fuelSaverAmountOff)} off / gal.`,
              ribbonType: FUEL_SAVER
          }
        : null;
};

const createCouponRibbon = (coupon, hideCouponDetails, onSeeCouponDetailsClick, isList): IRibbon | null => {
    if (!coupon || (coupon.offerState && [SHUT_OFF, REDEEMED].includes(coupon.offerState))) {
        return null;
    }

    const baseCoupon = {
        ribbonType: COUPON
    };

    if (hideCouponDetails) {
        return baseCoupon;
    }

    return {
        ...baseCoupon,
        description: (
            <StyledLinkButton
                aria-label={`Open ${coupon.valueText} coupon details`}
                data-analytics-type={
                    isList === undefined
                        ? 'product tile'
                        : isList === true
                        ? 'product list tile'
                        : 'product carousel tile'
                }
                data-analytics-value="coupon details"
                onClick={() =>
                    onSeeCouponDetailsClick
                        ? onSeeCouponDetailsClick(coupon.couponId)
                        : postMessageToIframeParent({
                              action: 'open-coupon-modal',
                              body: {couponId: coupon.couponId}
                          })
                }
            >
                {coupon.valueText || 'See Details'}
            </StyledLinkButton>
        )
    };
};

const createWicEligibleRibbon = (isWicEligible: boolean) => {
    if (!isWicEligible) {
        return null;
    }

    return {
        ribbonType: WIC_ELIGIBLE
    };
};

const createSnapEligibleRibbon = (isSnapEligible: boolean) => {
    if (!isSnapEligible) {
        return null;
    }

    return {
        ribbonType: SNAP_ELIGIBLE
    };
};

interface ICreateRibbonProps {
    clpePromotions?: any[];
    clpeCartItemPromotions?: any[];
    coupon?: any;
    onSeeCouponDetailsClick?: (couponId: IDType) => void;
    hideCouponDetails?: () => void;
    isWicEligible?: boolean;
    isSnapEligible?: boolean;
    onSale: boolean;
    onFuelSaver: boolean;
    fuelSaver: number | string;
    quantity?: number | null;
    sources?: any[];
    isList?: boolean;
}

export const createRibbons = ({
    clpePromotions,
    clpeCartItemPromotions,
    coupon,
    onSeeCouponDetailsClick,
    hideCouponDetails,
    isWicEligible = false,
    isSnapEligible = false,
    onSale,
    onFuelSaver,
    fuelSaver,
    sources = [],
    quantity,
    isList
}: ICreateRibbonProps): IRibbon[] | null => {
    const amountOffPerItemPromotion = clpeCartItemPromotions?.find((promo) => promo.rewardType === NEW_PRICE);

    const frequentPurchaseSource = sources.find(({key}) => key === 'frequentPurchases');

    const ribbons: IRibbon[] = [];

    const addRibbon = (ribbon: IRibbon | null) => {
        if (ribbon) {
            ribbons.push(ribbon);
        }
    };

    addRibbon(createFrequentPurchaseRibbon(frequentPurchaseSource));
    addRibbon(createOnSaleRibbon(onSale, amountOffPerItemPromotion, clpePromotions, quantity));
    addRibbon(createFuelSaverRibbon(onFuelSaver, fuelSaver, clpePromotions));
    addRibbon(createCouponRibbon(coupon, hideCouponDetails, onSeeCouponDetailsClick, isList));
    addRibbon(createWicEligibleRibbon(isWicEligible));
    addRibbon(createSnapEligibleRibbon(isSnapEligible));

    return ribbons.length ? ribbons : null;
};
