import {useEffect, useState} from 'react';
import {H2, P} from '@hy-vee/web-core';
import {useSelector} from 'react-redux';
import {useQuery, useLazyQuery} from '@apollo/client';

import {useCustomerData} from 'client/hooks/customer-hooks';
import {EcommerceStatus} from 'autogen/globalTypes';

import {graphqlClient} from '../../../graphql/graphql-client';
import {THREE_LEGGED_AUTH_TYPE, TWO_LEGGED_AUTH_TYPE} from '../../../enums/auth-types';
import {formatDate} from '../../../utils/view-helpers/date-time-view-helpers';
import {getCouponV4ByCouponIdQuery, getRetailItemsByUPCs} from '../../../graphql/queries/coupon-queries';
import ProductCardCart from '../product-card/product-card-cart';

import {
    StyledFlexContainer,
    StyledModalHeader,
    StyledModalContent,
    StyledContent,
    StyledImage,
    StyledDescription,
    StyledTerms,
    StyledEndDate,
    StyledProductContainer
} from './styles';
import CouponActionCenter from './coupon-action-center';

const CouponModalBody = ({couponId}) => {
    const customer = useSelector((state) => state.customer);
    const isAuthenticated = useSelector((state) => state.isAuthenticated);
    const {storeId} = useCustomerData();
    const {fuelSaverCardUuid} = customer;
    const [itemLoadingError, setItemLoadingError] = useState(false);
    const [products, setProducts] = useState([]);
    const [getRetailItems, {data: itemData, error: itemError}] = useLazyQuery(getRetailItemsByUPCs);

    const authType = isAuthenticated ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE;
    const {data, error, loading} = useQuery(getCouponV4ByCouponIdQuery, {
        client: graphqlClient(),
        variables: {
            authType,
            couponId
        }
    });

    useEffect(() => {
        if (!data?.couponV4?.upcs || !storeId) {
            return;
        }

        getRetailItems({
            variables: {
                authType,
                // Convert the hyveeUpcs to ean13s by dropping the leading 0
                ean13s: data?.couponV4?.upcs.map((upc) => upc.slice(1)),
                storeId,
                storeIdString: storeId.toString()
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, storeId]);

    useEffect(() => {
        if (!itemData) {
            return;
        }

        setProducts(
            itemData.locationsByBusinessCode[0].retailItemsByEAN13s
                .filter(
                    (retailItem) =>
                        // make sure there's a store product otherwise product cards do not render correctly
                        // remove storeProduct check when we switch to retailItem-based product cards
                        retailItem.ecommerceStatus === EcommerceStatus.ACTIVE && retailItem.item.product?.storeProduct
                )
                .map((retailItem) => ({
                    item: {
                        retailItems: [retailItem],
                        ...retailItem.item
                    },
                    storeProduct: {
                        ...retailItem.item.product.storeProduct
                    },
                    ...retailItem.item.product
                }))
        );
    }, [itemData]);

    useEffect(() => {
        if (!itemError) {
            return;
        }

        setItemLoadingError(true);
    }, [itemError]);

    if (loading || error) {
        return null;
    }

    const coupon = data.couponV4;

    return (
        <StyledFlexContainer>
            <StyledModalHeader>{'Coupon Details'}</StyledModalHeader>
            <StyledModalContent>
                <StyledContent>
                    <StyledImage alt={coupon.brand} src={coupon.imageUrl} />
                    <StyledDescription>
                        <H2 as="h1">{coupon.valueText}</H2>
                        <P>{coupon.description}</P>
                        <StyledEndDate>{`End Date: ${formatDate(coupon.clipEndDate)}`}</StyledEndDate>
                    </StyledDescription>
                </StyledContent>
                <CouponActionCenter
                    coupon={coupon}
                    fuelSaverCardUuid={fuelSaverCardUuid}
                    isAuthenticated={isAuthenticated}
                />
                <StyledProductContainer data-testid="StyledProductContainer">
                    {itemLoadingError
                        ? 'Error loading related products.'
                        : products.map((product) => (
                              <ProductCardCart
                                  isList
                                  key={product.productId}
                                  productData={product}
                                  productId={product.productId}
                              />
                          ))}
                </StyledProductContainer>
                <StyledTerms>{coupon.terms}</StyledTerms>
            </StyledModalContent>
        </StyledFlexContainer>
    );
};

export default CouponModalBody;
